import { createSlice } from '@reduxjs/toolkit';

export const GarantiasSlice = createSlice({
    name: 'Garantias',
    initialState: {
        status: 'EN_ESPERA',
        garantias: [],
        garantia_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {

        clearStore: (state, action) => {
            state.status = 'EN_ESPERA';
            state.isLoading = false;
            state.garantias = [];
            state.garantia_edit = [];
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
            state.garantias = [];
            state.garantia_edit = [];
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.status = action.payload.msg;
            state.garantias = [];
            state.garantia_edit = [];
        },

        setGarantias: (state, action) => {
            state.isLoading = false;
            state.status = "GARANTIAS_CARGADAS";
            state.garantias = action.payload.garantias;
        },

        setGarantia: (state, action) => {
            state.isLoading = false;
            state.status = "GARANTIA_CARGADA";
            state.garantia_edit = action.payload.garantia_edit;
        }

    }
});

export const { clearStore, processAction, notprocessAction, setError, setGarantia, setGarantias } = GarantiasSlice.actions;