import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { startloadOrden } from "../ordenes/thunks";
import { clearStore, notprocessAction, processAction, setError, setGarantia, setGarantias } from "./slice";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


export const startloadGarantias = () => {

    // let fechaI = moment(data.fechaInicial).format('YYYY/MM/DD');
    // let fechaF = moment(data.fechaFinal).format('YYYY/MM/DD');

    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Garantias/getGarantias/`, {
            // // fechaInicial: fechaI,
            // // fechaFinal: fechaF,
            // tecnico: data.tecnico,
            // estado: data.estadoOrden,
        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setGarantias({ garantias: listItems }));
                // dispatch(setIndicadores({ indicadores: response.data.indicadores[0] }))
            }
            if (response.data.status == 404) {
                console.log(response);

                dispatch(setError())
            }

        }).catch(function (error) {
            console.log();
            // console.log(response);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}




export const startRegistroGarantia = ( folio,idEmp,garantias,idCliente,idEquipo,fotografias) => {
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let formData = new FormData();
        for (let i = 0; i < fotografias.length; i++) {
            formData.append('file[]', fotografias[i]);
        }

        await URL_API.post(`Garantias/postGarantia`, {
           folio_origen: folio,
           usuario_reg:idEmp,
           fecha_reg: fechaRegistro,
           hora_reg: horaRegistro,
           cliente_id: idCliente,
           equipo_id:idEquipo,
           servicios:garantias


        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                let folioX = response.data.folio;


                if (fotografias.length != 0) {
                    URL_API.post(`Garantias/postUploadGarantia/${folioX}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }).then(function (response) {
                        // console.log(response)
                        if (response.data.status = 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Exito',
                                text: 'Solicitud de Garantia registrada con el folio: ' + folioX +' puede dar seguimiento a la Solicitud, en el menu Garantias.',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.open(`./assets/reports/compGarantia.html?folio=${folioX}`, '_blank');
                                    dispatch(notprocessAction());
                                    dispatch(startloadOrden(folio));
                                    
                                }
                            });
                        }
                    }).catch(function (error) {
                        console.log(error)
                        return;
                    });
                }

            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                // title: 'No se asigno la Orden de Servicio... Intente de Nuevo'
                title: 'No se registro la Solicitud de Garantia'
            })



            // dispatch(loadDataUser(id_emp));
            return;
        });

    }

}

export const startloadGarantia = (folio) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Garantias/getGarantiaDetail/${folio}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setGarantia({ garantia_edit: listItems }))
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible encontrar la garantia que ingresaste. Introduce un Folio diferente...',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar la garantia que ingresaste... Introduce un Folio diferente',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}

export const startAsignacionGarantia = (capturo,idEmp,folio)=>{
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`Garantias/putAsignacionGarantia/${folio}`, {
            empleado: idEmp,
            asignado_por: capturo,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Garantia asignada con exito'
                })
                dispatch(startloadGarantia(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se asigno la Solicitud de Garantia... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }
    
}

export const startAprobacionnGarantia = (idEmp,folio,observaciones)=>{
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`Garantias/putAprobacionGarantia/${folio}`, {
            empleado: idEmp,
            observaciones: observaciones,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Garantia fue Aprobada con exito'
                })
                dispatch(startloadGarantia(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se Aprobo la Solicitud de Garantia... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }
    
}

export const startRechazoGarantia = (idEmp,folio,observaciones)=>{
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`Garantias/putRechazarGarantia/${folio}`, {
            empleado: idEmp,
            observaciones: observaciones,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Garantia fue Rechazada con exito'
                })
                dispatch(startloadGarantia(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se rechazo la Solicitud de Garantia... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }
    
}

export const startCompletaGarantia = (idEmp,folio,observaciones)=>{
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`Garantias/putCompletaGarantia/${folio}`, {
            // empleado: idEmp,
            observaciones: observaciones,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Garantia fue acompletada con exito'
                })
                dispatch(startloadGarantia(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se acompleto la Solicitud de Garantia... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }
    
}
export const startCerrarGarantia = (folio,recoge)=>{
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`Garantias/putCerrarGarantia/${folio}`, {
            // empleado: idEmp,
            // observaciones: observaciones,
            fecha: fechaRegistro,
            hora: horaRegistro,
            recoge: recoge

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Garantia fue Cerrada con exito'
                })
                window.open(`./assets/reports/compGarantiaEntrega.html?folio=${folio}`, '_blank');
                dispatch(startloadGarantia(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se acompleto la Solicitud de Garantia... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }
    
}