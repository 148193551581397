import { configureStore } from '@reduxjs/toolkit';
import { catalogosSlice } from './catalagos/slice';
import { usuarioSlice } from './usuarios/slice';
import { empleadosSlice } from './empleados/slice';
import { clientesSlice } from './clientes/slice';
import { equiposSlice } from './equipos/slice';
import { facturacionSlice } from './facturacion/slice';
import { ordenesSlice } from './ordenes/slice';
import { FinanzasSlice } from './finanzas/slice';
import { ReportSlice } from './reportes/slice';
import { salesSlice } from './sales/slice';
import { GarantiasSlice } from './garantias/slice';

export default configureStore({
  reducer: {
    usuario: usuarioSlice.reducer,
    empleados: empleadosSlice.reducer,
    clientes: clientesSlice.reducer,
    equipos: equiposSlice.reducer,
    facturacion: facturacionSlice.reducer,
    catalogos: catalogosSlice.reducer,
    finanzas: FinanzasSlice.reducer,
    reportes: ReportSlice.reducer,

    //Operativo
    ordenes: ordenesSlice.reducer,
    ventasPedido: salesSlice.reducer,
    garantias: GarantiasSlice.reducer

  },
})