import React from 'react'
import { AlertTriangle, Search } from 'react-feather';
import { useForm } from 'react-hook-form';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { startloadPerformance } from '../../../../app/store/reportes/thunks';
import * as XLSX from 'xlsx/xlsx.mjs';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
export const Performance = () => {

    const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
    const { empleados } = useSelector(state => state.empleados);
    const { isLoading, status_report, performance } = useSelector(state => state.reportes);
    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;

    const dispatch = useDispatch();
    // console.log(empleados);


    const searchOrdenes = (data) => {
        // console.log(data);
        dispatch(startloadPerformance(data.fechaInicial, data.fechaFinal, data.tecnico))
    }
    const exportToXLS = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `Reporte_Rendimiento.xlsx`);
    }


    const loadFolio = (folio) => {

        if (folio.includes("HDLV")) {
            dispatch(startloadSaleReport(folio))
        } else {
            dispatch(startloadOrden(folio));
        }

    }


    return (
        <div>
            <h2 className="h4  fw-bold">Rendimiento</h2>
            <div>
                <div className="card-body">

                    <form className='row' onSubmit={handleSubmit(searchOrdenes)} >

                        <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                            <label className="form-label">Tecnico:</label>

                            <select className="form-select " defaultValue={'0'}  {...register("tecnico")} >
                                <option value={'0'} >TODOS</option>
                                {
                                    empleados.map((item, index) => (
                                        item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                                            : <option key={index} style={{ display: "none" }} ></option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <label className="form-label">Fecha de Inicio:</label>
                            <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
                            {
                                errors.fechaInicial &&
                                <div className='text-left-5 mt-2'>
                                    <AlertTriangle size="18" color="#dc3545" />
                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                                </div>
                            }
                        </div>
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
                            <label className="form-label">Fecha de Termino:</label>
                            <input type="date" className="form-control" defaultValue={today} {...register("fechaFinal")} />
                        </div>

                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                            <div className="mb-3 mt-4">
                                <button type="submit" className="btn btn-outline-secondary">
                                    <Search size="20" /> Buscar
                                </button>
                            </div>
                        </div>
                    </form>
                    <hr className='my-0' />

                    <div className='d-flex justify-content-between flex-md-nowrap align-items-center pt-3 pb-1 mb-1 border-bottom'>
                        <h5 className='fw-bold'>Resultados</h5>

                        <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold"
                            onClick={() => exportToXLS(performance)}
                        >
                            <RiFileExcel2Line size={25} /> <span className='text-left-5 align-middle'> Exportar </span>
                        </button>

                    </div>
                    <div className="table-responsive my-0 table-scrollbar_esp_reporte mt-3">



                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center th-sticky">#</th>
                                    <th scope="col" className="text-center th-sticky">FOLIO</th>
                                    <th scope="col" className="text-center th-sticky">TECNICO</th>
                                    <th scope="col" className="text-center th-sticky">ASIGNACION</th>
                                    <th scope="col" className="text-center th-sticky">DIAGNOSTICO</th>
                                    <th scope="col" className="text-center th-sticky">E.COMPONENTE</th>
                                    <th scope="col" className="text-center th-sticky">TERMINADA</th>
                                    <th scope="col" className="text-center th-sticky">APROBADA A ENTREGA</th>
                                    <th scope="col" className="text-center th-sticky">CERRADA</th>

                                </tr>
                            </thead>
                            <tbody className="">
                                {
                                    performance?.length == 0 ?
                                        <>
                                            <tr>
                                                <td colSpan={8}>No se han encontrado datos con los parametros de busqueda actuales</td>
                                            </tr>
                                        </> :
                                        performance?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(item.FOLIO)}>{item.FOLIO}</button>
                                                </td>
                                               
                                                <td className="text-start">{item.TECNICOASIGNADO}</td>
                                                <td className="text-center">{item.TIEMPO_HASTA_ASIGNACION_2}</td>
                                                <td className="text-center">{item.TIEMPO_HASTA_DIAGNOSTICO_3}</td>
                                                <td className="text-center">{item.TIEMPO_EN_ESPERA_COMPONENTE_4}</td>
                                                <td className="text-center">{item.TIEMPO_TERMINADA_5}</td>
                                                {/* <td className="text-center">{item.TIEMPO_NO_APROBADA_ENTREGA_6}</td> */}
                                                <td className="text-center">{item.TIEMPO_HASTA_COMPLETADA_7}</td>
                                                <td className="text-center">{item.TIEMPO_HASTA_CERRADA_8}</td>
                                                
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>



                    </div>


                </div>
            </div>




        </div>
    )
}
