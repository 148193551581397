
// Rutas para acceder al contenido del sistema.

export const connectionFiles = {
    // csfURL:'http://127.0.0.1/api_app/files/csf',
    // imgsRETIROS:'http://127.0.0.1/api_app/files/or_retiros',
    // imgsGarantia:'http://127.0.0.1/api_app/files/garantia_recibido',

    csfURL: 'https://hdltechnology.com.mx/capacitacion/api/files/csf',
    imagenes: 'https://hdltechnology.com.mx/capacitacion/api/files/imagenes',
    imgsRecURL: 'https://hdltechnology.com.mx/capacitacion/api/files/or_equipo_recibido',
    imgsEvidenciasURL: 'https://hdltechnology.com.mx/capacitacion/api/files/or_evidencias',
    imgsRETIROS: 'https://hdltechnology.com.mx/capacitacion/api/files/or_retiros',
    imgsEvidenciasProd: 'https://hdltechnology.com.mx/capacitacion/api/files/prod_evidencias',
    imgsGarantia: 'https://hdltechnology.com.mx/capacitacion/api/files/garantia_recibido',

    // csfURL:'https://hdltechnology.com.mx/sistema/api/files/csf',
    // imagenes:'https://hdltechnology.com.mx/sistema/api/files/imagenes',
    // imgsRecURL:'https://hdltechnology.com.mx/sistema/api/files/or_equipo_recibido',
    // imgsRETIROS:'https://hdltechnology.com.mx/sistema/api/files/or_retiros',
    // imgsEvidenciasURL:'https://hdltechnology.com.mx/sistema/api/files/or_evidencias',
    // imgsEvidenciasProd:'https://hdltechnology.com.mx/sistema/api/files/prod_evidencias',
    // imgsGarantia:'https://hdltechnology.com.mx/sistema/api/files/garantia_recibido',
}