import { createSlice } from '@reduxjs/toolkit';

export const ReportSlice = createSlice({
    name: 'Reports',
    initialState: {
        status_report: 'EN_ESPERA',
        facturacion: [],
        sumaryOrders: [],
        accountsReceivable: [],
        performance:[],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        clearStore: (state, action) => {
            state.status_report = 'EN_ESPERA';
            state.facturacion = [];
            state.sumaryOrders = [];
            state.accountsReceivable = [];
            state.performance = [];
            // state.orden_edit = [];
            // state.indicadores = [];
            // state.refacciones = [];w
            // state.facturacion_edit = [];
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status_report = 'PROCESANDO_PETICION';
            state.facturacion = [];
            state.sumaryOrders = [];
            state.accountsReceivable = [];
            state.performance = [];
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status_report = 'EN_ESPERA';
        },
        setFacturacion: (state, action) => {
            state.isLoading = false;
            state.status_report = "SOLICITUDES_FACTURACION_CARGADAS";
            state.facturacion = action.payload.facturacion;
        },
        setSumaryOrders: (state, action) => {
            state.isLoading = false;
            state.status_report = "ORDENES_CARGADAS";
            state.sumaryOrders = action.payload.sumaryOrders;
        },
        setAccounts: (state, action) => {
            state.isLoading = false;
            state.status_report = "CUENTAS_CARGADAS";
            state.accountsReceivable = action.payload.accountsReceivable;
        },
        setPerformance: (state, action) => {
            state.isLoading = false;
            state.status_report = "REPORTE_RENDIMIENTO_CARGADO";
            state.performance = action.payload.performance;
        },
        setError:(state,action)=>{
            state.isLoading=false;
            state.status_report=action.payload.msg;
            state.facturacion =[];
        }

       
    }
});


// Action creators are generated for each case reducer function
export const { processAction,notprocessAction,setFacturacion ,setError,setSumaryOrders,setAccounts,setPerformance,clearStore} = ReportSlice.actions;