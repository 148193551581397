import React from 'react'
import { useSelector } from 'react-redux';
import { connectionFiles } from '../../app/filesConnection';



export const M_GaleriaGarantia = () => {

    const { garantia_edit, isLoading, status } = useSelector(state => state.garantias);
    let urlImg = garantia_edit[0]["IMGS"];
    return (
        <div className="modal fade" id="galeryWarranties" aria-labelledby="exampleModalLabelA" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabelA">Galeria: Equipo recibido <span className="badge text-bg-dark">{garantia_edit[0].FOLIO} </span> </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            {
                                urlImg.map((item, index) => (
                                    <div className="" key={index}>
                                        
                                        
                                      
                                        <img src={`${connectionFiles['imgsGarantia']}/${item.IMAGEN}`} className="d-block w-100" />
                                        <div className="text-center mt-2">
                                            <a href={`${connectionFiles['imgsGarantia']}/${item.IMAGEN}`} target="_blank" className="btn btn-primary">Ver en tamaño completo</a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                            Cerrar
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
